import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { LoginButton } from "./LoginButton";
import { CartContext } from "./cart/CartProvider";

const Header = ({ siteTitle }) => (
	<header
		style={{
			background: `#335299`,
			marginBottom: `1.45rem`,
		}}
	>
		<div
			style={{
				margin: `0 auto`,
				maxWidth: 960,
				padding: `1.45rem 1.0875rem`,
			}}
		>
			<h1 style={{ margin: 0 }}>
				<Link
					to="/"
					style={{
						color: `white`,
						textDecoration: `none`,
					}}
				>
					{siteTitle}
				</Link>
			</h1>
			<LoginButton />
			<CartContext.Consumer>
				{({ items }) => <p>items in cart: {items.length}</p>}
			</CartContext.Consumer>
		</div>
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string,
};

Header.defaultProps = {
	siteTitle: ``,
};

export default Header;
