import React, { FunctionComponent, useContext } from "react";
import { IdentityUserContext } from "./identity/Identity";
import netlifyIdentity from "netlify-identity-widget";

export const LoginButton: FunctionComponent<{}> = () => {
	const user = useContext(IdentityUserContext);
	return (
		<>
			{user ? (
				<button onClick={() => netlifyIdentity.logout()}>Logout</button>
			) : (
				<button onClick={() => netlifyIdentity.open()}>Login / Signup</button>
			)}
		</>
	);
};
